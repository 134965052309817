import { SocialUser } from '@abacritt/angularx-social-login';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ROUTES } from '@app/core/constants/auth.constants';
import { CONSTANTS } from '@app/core/constants/local-storage.constants';
import { ROLES, RoleType } from '@app/core/constants/roles.constants';
import { PATHS } from '@app/core/interfaces/utils/router-path';
import { LocalStorageService } from '@app/core/services/localStorage/local-storage.service';
import { HttpService } from '@core/http/http.service';
import {
  AuthLoginResponse,
  AuthLoginResponsePermissions,
  AuthLoginResponseStoredUser,
  Company,
  LevelsAvailable,
  ResponseSendCode,
  Success2FACodeValidationResponse,
  TokenResponse,
  User,
  UserCreate,
  ValidateAccountResponse,
  ValidationMethod,
} from '@core/interfaces/auth/auth.interface';
import { BehaviorSubject, Observable, take } from 'rxjs';

import { UserDataService } from '../user/user-data-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private basePath = 'auth';
  private _user = new BehaviorSubject<AuthLoginResponseStoredUser | null>(null);
  private _permissions =
    new BehaviorSubject<AuthLoginResponsePermissions | null>(null);
  public refreshTokenSubject: BehaviorSubject<TokenResponse | null> =
    new BehaviorSubject<TokenResponse | null>(null);
  public userLoggedIn$ = this._user.asObservable();
  public userPermissions$ = this._permissions.asObservable();
  public permissionSummaryList: { [x: string]: boolean } = {};

  constructor(
    protected httpService: HttpService,
    private userDataService: UserDataService,
    private dialogRef: MatDialog,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {
    const user = this.userDataService.getUser() as AuthLoginResponseStoredUser;
    const permissions =
      this.userDataService.getPermissions() as AuthLoginResponsePermissions;
    this._user.next(user);
    this._permissions.next(permissions);
    this.userPermissions$.subscribe(() => {
      this.setPermissionsSummaryList();
    });

    this.refreshTokenSubject.subscribe((tokenData: TokenResponse | null) => {
      if (tokenData) {
        this.httpService.setHeader(
          'Authorization',
          `Bearer ${tokenData.token}`
        );
        this.httpService.setToken(tokenData.token);
      }
    });
  }

  /**
   *
   * @param user
   */
  public setPermissions(permissions: AuthLoginResponsePermissions): void {
    this._permissions.next(permissions);
  }

  /**
   *
   */
  public getPermissions(): AuthLoginResponsePermissions {
    return this._permissions.value as AuthLoginResponsePermissions;
  }

  /**
   *
   * @param loginData
   */
  public saveLoginData(loginData: Success2FACodeValidationResponse) {
    this._user.next(loginData.user);
    this._permissions.next(loginData.permissions);
    this.userDataService.setUser(loginData.user);
    this.userDataService.setPermissions(loginData.permissions);
    this.userDataService.setToken(loginData.token);
    this.userDataService.setRefreshToken(loginData.refreshToken);
    this.httpService.setHeader('Authorization', `Bearer ${loginData.token}`);
  }

  public setPermissionsSummaryList() {
    this.permissionSummaryList = {};
    this._permissions.getValue()?.forEach(item => {
      this.permissionSummaryList[item] = true;
    });
  }
  public hasPermission(name: string) {
    return this.permissionSummaryList[name];
  }

  public getUser(): AuthLoginResponseStoredUser {
    return this._user.value as AuthLoginResponseStoredUser;
  }
  public getCompanySelected(): Company {
    return this.userDataService.getItem(CONSTANTS.COMPANY_SELECTED) as Company;
  }

  public checkRole(roleTag: RoleType) {
    return this.getUser().role.tag === roleTag;
  }

  public isRoleWithCompanies() {
    return (
      this.getUser().role.tag === ROLES.USUARIO_TRANSPORTE ||
      this.getUser().role.tag === ROLES.USUARIO_CHOFER
    );
  }

  /**
   *
   * @param loginData
   */
  public deleteLoginData() {
    this._user.next(null);
    this._permissions.next(null);
    this.userDataService.removeCompanySelected();
    this.userDataService.removeUser();
    this.userDataService.removePermissions();
    this.userDataService.removeRefreshToken();
    this.userDataService.removeToken();
    this.userDataService.removeRefreshToken();
    this.userDataService.removeLevels();
    this.httpService.resetToken();
    this.dialogRef.closeAll();
  }

  /**
   *
   * @returns
   */
  public login(
    email: string,
    password: string,
    recaptcha: string
  ): Observable<AuthLoginResponse<ValidateAccountResponse | LevelsAvailable>> {
    const body = { email, password, recaptcha };
    return this.httpService.post<
      AuthLoginResponse<ValidateAccountResponse | LevelsAvailable>
    >(`${this.basePath}`, body);
  }

  /**
   *
   * @param token
   * @param validationMethod
   * @returns
   */
  public sendCodeToEmail(
    token: string,
    validationMethod: string,
    recaptcha: string
  ): Observable<AuthLoginResponse<ResponseSendCode | string>> {
    const body = { recaptcha, token, validationMethod };
    return this.httpService.post<AuthLoginResponse<ResponseSendCode | string>>(
      `${this.basePath}/send-code`,
      body
    );
  }

  /**
   *
   * @param token
   * @returns
   */
  public sendValidationToDuo(
    token: string,
    recaptcha: string
  ): Observable<Success2FACodeValidationResponse> {
    const body = { recaptcha, token, validationMethod: ValidationMethod.DUO };
    return this.httpService.post<Success2FACodeValidationResponse>(
      `${this.basePath}/send-code`,
      body
    );
  }

  /**
   *
   * @param code
   * @param token
   * @returns
   */
  public validate2FACode(
    code: string,
    email: string,
    token: string,
    recaptcha: string
  ): Observable<Success2FACodeValidationResponse> {
    const body = { code, email, recaptcha, token };
    return this.httpService.post<Success2FACodeValidationResponse>(
      `${this.basePath}/validate-code`,
      body
    );
  }

  /**
   *
   * @param email
   * @returns boolean
   */
  public checkEmail(email: string): Observable<boolean> {
    const body = { email };
    return this.httpService.post<boolean>(`${this.basePath}/check`, body);
  }

  /**
   *
   * @param user: UserCreate
   * @returns User
   */
  public register(
    user: UserCreate,
    recaptcha: string
  ): Observable<AuthLoginResponse<User>> {
    return this.httpService.post<AuthLoginResponse<User>>(
      `${this.basePath}/register`,
      { ...user, recaptcha }
    );
  }

  /**
   *
   * @param user: SocialUser
   * @returns User
   */
  public registerSocial(
    user: SocialUser
  ): Observable<Success2FACodeValidationResponse> {
    return this.httpService.post<Success2FACodeValidationResponse>(
      `${this.basePath}/register/social`,
      { ...user }
    );
  }

  /**
   *
   * @param email: string
   * @returns string
   */
  public sendAccountValidation(
    email: string
  ): Observable<AuthLoginResponse<string>> {
    return this.httpService.post<AuthLoginResponse<string>>(
      `${this.basePath}/validate-account`,
      { email }
    );
  }

  /**
   *
   * @param user: string
   * @returns string
   */
  public socialLogIn(
    user: SocialUser
  ): Observable<Success2FACodeValidationResponse> {
    return this.httpService.post<Success2FACodeValidationResponse>(
      `${this.basePath}/social-login`,
      { user }
    );
  }

  /**
   *
   * @param email: string
   * @returns string
   */
  public resetPassword(
    email: string,
    recaptcha: string
  ): Observable<AuthLoginResponse<string>> {
    return this.httpService.post<AuthLoginResponse<string>>(
      `${this.basePath}/reset-password`,
      { email, recaptcha }
    );
  }

  /**
   *
   * @param email
   * @returns boolean
   */
  public refreshToken(refreshToken: string): Observable<TokenResponse> {
    return this.httpService.get<TokenResponse>(
      `${this.basePath}/refresh/${refreshToken}`
    );
  }

  public logOut(): Observable<AuthLoginResponse<string>> {
    const refreshToken = this.userDataService.getRefreshToken();
    return this.httpService.post<AuthLoginResponse<string>>(
      `${this.basePath}/logout`,
      { refreshToken }
    );
  }

  public saveAndRedirect(
    response: Success2FACodeValidationResponse,
    social = false
  ) {
    const roleWithCompanies =
      response.user.role.tag === ROLES.USUARIO_TRANSPORTE ||
      response.user.role.tag === ROLES.USUARIO_CHOFER;

    if (response.token) {
      this.localStorageService.removeItem(CONSTANTS.TOKEN);
      this.localStorageService.removeItem(CONSTANTS.LEVELS);
      this.saveLoginData(response);
      this.localStorageService.setItem(CONSTANTS.SOCIAL_USER, social);
      const expirationId = this.localStorageService.getItem(
        CONSTANTS.EXPIRATION
      ) as number;
      if (expirationId) {
        this.localStorageService.removeItem(CONSTANTS.EXPIRATION);
        clearTimeout(expirationId);
      }
      if (this.isTransportistaWithoutCompany(response.user)) {
        this.router.navigate([`/${PATHS.AUTH.BASE}/${PATHS.AUTH.CUIT.BASE}`]);
        return;
      }

      if (
        roleWithCompanies &&
        response.user.userCompanies[0].company.companyBusinessTypes.length > 1
      ) {
        this.router.navigate([
          `/${PATHS.AUTH.BASE}/${PATHS.AUTH.BUSINESS.BASE}`,
        ]);
        return;
      }

      this.router.navigate([ROUTES[response.user.role.tag]]);
    }
  }

  private isTransportistaWithoutCompany(
    user: AuthLoginResponseStoredUser
  ): boolean {
    const roleWithCompanies =
      user.role.tag === ROLES.USUARIO_TRANSPORTE ||
      user.role.tag === ROLES.USUARIO_CHOFER;
    return !user.userCompanies.length && roleWithCompanies;
  }

  /**
   *
   * @param email
   * @param status
   * @returns boolean
   */
  public changeUserStatus(email: string, status: boolean): Observable<string> {
    return this.httpService.patch<string>(`${this.basePath}/status`, {
      email,
      status,
    });
  }

  public async inMaintenance(): Promise<boolean> {
    return new Promise(resolve => {
      this.httpService
        .get<string>('ping')
        .pipe(take(1))
        .subscribe({
          error: error => {
            // si devuleve 503 esta en mantenimineto
            resolve(error.status === 503);
          },
          next: () => {
            resolve(false);
          },
        });
    });
  }
}
